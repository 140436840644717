import React, { useState, useEffect } from 'react';
import '../styles/globals.css';
import Head from 'next/head';
import Script from 'next/script';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ProviderProvider } from '@olo-web/components/templates/ProviderProvider';
import { AppLayout } from '@olo-web/components/templates/AppLayout';
import { CustomThemeDraftContextProvider } from '@olo-web/client-state';
import { Scripts } from '@organisms/Scripts/Scripts';
import { ErrorBoundary } from '@organisms/ErrorBoundary/ErrorBoundary.ui';
import { useMenu } from '../domain/menu/queries/useMenu.ts';
import { initializeFaro } from '@grafana/faro-web-sdk';
import faroConfig from '../utils/metrics/grafana/grafanaInstrumentation.ts';

import 'focus-visible/dist/focus-visible';
import { Poppins } from 'next/font/google';

const poppins = Poppins({
  weight: ['400', '500', '600', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  fallback: ['sans-serif'],
  variable: '--font-poppins',
});

// Localdev polyfill for non-babel dev watcher?
if (process?.env?.NODE_ENV === 'development' && typeof Promise.withResolvers === 'undefined') {
  console.warn('Promise.withResolvers polyfill applied');
  Promise.withResolvers = function () {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';

function MyApp({ Component, pageProps }) {
  if (typeof window !== 'undefined')
    console.log(`SpotOn Order ${process.env.NEXT_PUBLIC_APP_VERSION}`);
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      initializeFaro(faroConfig);
    }
  }, []);
  const [queryClient] = useState(() => new QueryClient());
  const menuQueryIndex = pageProps?.dehydratedState?.queries?.findIndex(
    (query) => query?.queryKey?.[0] === 'menu'
  );

  const menuQueryKey = pageProps?.dehydratedState?.queries?.[menuQueryIndex]?.queryKey;
  if (Date.now() - Number(pageProps.timeStamp) > 60000) {
    const queryArray = ['menu', menuQueryKey?.[0], menuQueryKey?.[1], menuQueryKey?.[2]];

    if (menuQueryIndex !== -1) {
      pageProps?.dehydratedState?.queries.splice(menuQueryIndex, 1);
      useMenu()
        .then((data) => queryClient.setQueryData(queryArray, data))
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, user-scalable=yes, initial-scale=1, maximum-scale=5, viewport-fit=cover"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:title" key="og:title" content="SpotOn - Online Ordering" />
        <meta property="og:description" key="og:description" content="Order Online with Spot On" />
        <meta name="description" content="SpotOn OLO Web" />
        <link rel="icon" href="data:," />
      </Head>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`}
        async
        defer
      />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CustomThemeDraftContextProvider>
            <ProviderProvider globalUIState={pageProps.globalUIState}>
              <Scripts />
              <AppLayout className={poppins.className}>
                <ErrorBoundary>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </AppLayout>
            </ProviderProvider>
          </CustomThemeDraftContextProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
