import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import { useCheckoutDispatch } from '@olo-web/client-state';
import { useCustomerDispatch } from '@olo-web/client-state';
import { clearUserCookie } from 'react-fullstory';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { removeUser } from '@olo-web/utils/metrics/prometheus';

export const logout = async (): Promise<any> => {
  return { ok: true };
};

export function useLogout(options?: UseMutationOptions<unknown, unknown, unknown>) {
  const customerDispatch = useCustomerDispatch();
  const checkoutDispatch = useCheckoutDispatch();
  const queryClient = useQueryClient();
  const { sendEvent } = useSendEvent();
  return useMutation(logout, {
    ...options,
    onSuccess: () => {
      clearUserCookie(true);
      customerDispatch({ type: 'CLEAR' });
      queryClient.setQueryData('customer', undefined);
      sendEvent(EAnalyticsEventNames.LOG_OUT);

      checkoutDispatch({
        type: 'CLEAR_SELECTED_CARD',
      });
      removeUser();
      if (options?.onSuccess) options.onSuccess(null, null, null);
    },
    onError: (error) => {
      console.error('Error signing out', error);
    },
  });
}
