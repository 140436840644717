import { isKnownError } from '@olo-web/utils/common/functions';
import { NextApiRequest, NextApiResponse } from 'next';
import { metrics } from '@opentelemetry/api';

/*
 * Prepare Metrics
 */

// serviceName should match /instrumentation.ts
const serviceName = 'olo-web-refactor';
const metricPrefix = 'olo_web_refactor';

// Instance of OTEL metrics
const meter = metrics.getMeter(serviceName);

const buildSha = process.env.NEXT_PUBLIC_BUILD_COMMIT_SHA || '';
const standardLabels: Record<string, any> = {};
if (buildSha) {
  standardLabels.buildSha = buildSha;
}

/*
 * Custom Metric Handlers
 */

export const metricApiHistogram = meter.createHistogram(`${metricPrefix}_api_route_duration`, {
  description:
    'Duration of API calls with route status and method. Manually extending auto-instrumentation as wrapper.',
});
export type APIHandlerFunction = (eq: NextApiRequest, res: NextApiResponse) => Promise<void>;
export const apiMetricWrapper = (
  apiFunc: APIHandlerFunction,
  pathName: string,
  labels: Record<string, any> = {}
) => {
  return async (req: NextApiRequest, res: NextApiResponse) => {
    const tsStart = performance.now();
    let caughtError: string | undefined;
    let statusCode = 200;
    try {
      await apiFunc(req, res);
      statusCode = res.statusCode;
    } catch (e) {
      caughtError = e?.name ?? 'Error';
      if (res.statusCode >= 400) {
        statusCode = res.statusCode;
      } else {
        statusCode = e?.response?.status ?? 500;
      }
      throw e;
    } finally {
      const tsDeltaMS = performance.now() - tsStart;
      const metricAttributes: Record<string, any> = {
        ...standardLabels,
        ...labels,
        pathName,
        method: req.method ?? 'UNKNOWN',
        statusCode,
      };
      if (caughtError) {
        metricAttributes.error = caughtError;
      }
      metricApiHistogram.record(tsDeltaMS, metricAttributes);
    }
  };
};

/*
 * Datadog Conversion Metric Handlers
 */

export interface Context {
  source: string;
  payload: any;
}

export const metricTrackError = meter.createCounter(`${metricPrefix}_track_error`, {
  description: 'Datadog RUM conversion TrackError event',
});
export const trackError = async (err: Error, errorContext?: Context): Promise<void> => {
  if (isKnownError(err.message)) {
    return;
  }
  metricTrackError.add(1, {
    ...standardLabels,
    source: errorContext?.source ?? 'unknown',
    error: err.name,
  });
};

export const metricTrackEvent = meter.createCounter(`${metricPrefix}_track_event`, {
  description: 'Datadog RUM conversion TrackEvent event',
});
export const trackEvent = async (actionName: string, actionContext: Context): Promise<void> => {
  actionName;
  actionContext;

  metricTrackEvent.add(1, {
    ...standardLabels,
    source: actionContext?.source ?? 'unknown',
    actionName,
  });
};

type TDDIdentify = {
  email?: string;
  id?: string;
  name?: string;
};
export const metricIdentifyUser = meter.createCounter(`${metricPrefix}_identify_user`, {
  description: 'Datadog RUM conversion IdentifyUser event',
});
export const identifyUser = (_tddIdentify: TDDIdentify) => {
  metricIdentifyUser.add(1, {
    ...standardLabels,
  });
};

export const metricRemoveUser = meter.createCounter(`${metricPrefix}_remove_user`, {
  description: 'Datadog RUM conversion RemoveUser event',
});
export const removeUser = () => {
  metricRemoveUser.add(1, {
    ...standardLabels,
  });
};
