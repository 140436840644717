export * from './countries';
export * from './friendlyErrorMessages';

const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const natsUrl = process.env.NEXT_PUBLIC_NATS_URL;
const emagineURI = process.env.EMAGINE_BACKEND_URI;
const egiftUrl = process.env.EGIFT_BACKEND_URI;

export const API_URL = apiUrl;
export const EGIFT_URL = egiftUrl;
export const IS_SERVER = typeof window === 'undefined';
export const IS_DEV = process?.env?.NODE_ENV === 'development';
export const IS_QA = !IS_SERVER && window?.location?.href?.includes('olo-web-staging.qa');
export const IS_PROD = !(IS_DEV || IS_QA);
export const IS_IOS = !IS_SERVER && !window?.navigator?.platform?.match(/iPhone|iPod|iPad/);
export const EMAGINE_PREFIX =
  emagineURI && emagineURI.includes('restaurantapi') ? 'socaolo-v1' : 'socaolo';
export const EMAGINE_URI = `${emagineURI}/${EMAGINE_PREFIX}`;

export const MAIN_CONTAINER_MAX_WIDTH = '115rem';

export const MIN_PW_LENGTH = 9;

export const NATS_URL = natsUrl;

export const BASE_URL = IS_DEV
  ? 'http://localhost:3000'
  : IS_QA
  ? 'https://https://olo-web-refactor-staging.qa.spoton.sh/'
  : 'https://order.spoton.com';

export const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60;
