import { createStore } from '@olo-web/utils/common/store';

interface IGlobalUIState {
  mapCenter?: { lat: string; long: string; loading: boolean };
  routerLoading?: boolean;
  bagOpen?: boolean;
  hasSentUpsellCarouselShownEvent: boolean;
  hasSentUpsellCarouselNotShownEvent: boolean;
  collectJs: null | Record<string, any>;
  kountSessionId: null | string;
  ldUserKey: null | string;
  customerId: null | string;
}

type TGeoLocationCaptured = IStandardAction<'MAP_CENTER', any>;
type TSetCollectJs = IStandardAction<'SET_COLLECTJS', Record<string, any>>;
type TSetKountSessionId = IStandardAction<'SET_KOUNT_SESSIONID', string>;
type TSetLDUserKey = IStandardAction<'SET_LD_USER_KEY', string>;
type TSetCustomerId = IStandardAction<'SET_CUSTOMER_ID', string>;
type TSetRouterLoading = Omit<IStandardAction<'ROUTER_IS_LOADING'>, 'payload'>;
type TCancelRouterLoading = Omit<IStandardAction<'CANCEL_ROUTER_LOADING'>, 'payload'>;
type TOpenBag = Omit<IStandardAction<'OPEN_BAG'>, 'payload'>;
type TCloseBag = Omit<IStandardAction<'CLOSE_BAG'>, 'payload'>;
type TSentUpsellCarouselShownEvent = TEmptyAction<'SENT_UPSELL_CAROUSEL_SHOWN_EVENT'>;
type TSentUpsellCarouselNotShownEvent = TEmptyAction<'SENT_UPSELL_CAROUSEL_NOT_SHOWN_EVENT'>;
type TGlobalUIAction =
  | TGeoLocationCaptured
  | TSetRouterLoading
  | TCancelRouterLoading
  | TSetLDUserKey
  | TSentUpsellCarouselShownEvent
  | TSentUpsellCarouselNotShownEvent
  | TOpenBag
  | TSetCollectJs
  | TSetKountSessionId
  | TCloseBag
  | TSetCustomerId;

const initialState: IGlobalUIState = {
  mapCenter: { lat: '', long: '', loading: true },
  routerLoading: false,
  hasSentUpsellCarouselShownEvent: false,
  hasSentUpsellCarouselNotShownEvent: false,
  collectJs: null,
  kountSessionId: null,
  ldUserKey: null,
  customerId: null,
};

const reducer = (state: IGlobalUIState, action: TGlobalUIAction): IGlobalUIState => {
  switch (action.type) {
    case 'SET_COLLECTJS':
      return { ...state, collectJs: action.payload };
    case 'SET_KOUNT_SESSIONID':
      return { ...state, kountSessionId: action.payload };
    case 'SET_LD_USER_KEY':
      return { ...state, ldUserKey: action.payload };
    case 'SET_CUSTOMER_ID':
      return { ...state, customerId: action.payload };
    case 'MAP_CENTER':
      return { ...state, mapCenter: action.payload };
    case 'ROUTER_IS_LOADING':
      return { ...state, routerLoading: true };
    case 'CANCEL_ROUTER_LOADING':
      return { ...state, routerLoading: false };
    case 'OPEN_BAG':
      return {
        ...state,
        bagOpen: true,
      };
    case 'CLOSE_BAG':
      return {
        ...state,
        bagOpen: false,
      };
    case 'SENT_UPSELL_CAROUSEL_SHOWN_EVENT':
      return {
        ...state,
        hasSentUpsellCarouselShownEvent: true,
      };
    case 'SENT_UPSELL_CAROUSEL_NOT_SHOWN_EVENT':
      return {
        ...state,
        hasSentUpsellCarouselNotShownEvent: true,
      };
    default:
      return state;
  }
};

export const [GlobalUIContextProvider, useGlobalUIDispatch, useGlobalUIState] = createStore<
  IGlobalUIState,
  TGlobalUIAction
>(reducer, initialState, {
  name: 'globalUI',
});
