export enum EAnalyticsEventNames {
  DEFAULT = 'analyticsEvent',
  TABLE_NUMBER_REQUIRED = 'table-number-required',
  INVALID_ORDER_TIME = 'invalid-order-time',
  SIGNIFICANT_ASAP_CHANGE = 'significant-asap-change',
  INACTIVE_DISCOUNT = 'inactive-discount',
  INVALID_TABLE_NUMBER = 'invalid-table-number',
  CONVERTED_TO_ASAP = 'converted-to-asap',
  MULTIPLE_VALIDATION_ERRORS = 'multiple-validation-errors',
  UPSELL_CAROUSEL_SHOWN = 'upsellCarouselShown',
  PACING_CAPACITY_ERROR = 'pacing-capacity-error',
  UPSELL_CAROUSEL_NOT_SHOWN = 'upsellCarouselNotShown',
  UPSELL_SHEET_NOT_SHOWN = 'upsellSheetNotShown',
  UPSELL_SHEET_SHOWN = 'upsellSheetShown',
  MENU_SEARCH = 'menu_search',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  ADD_RECOMMENDED_ITEM = 'add_recommended_item',
  UPDATE_ITEM = 'update_item',
  ORDER_STARTED = 'order_started',
  ORDER_CONTINUED = 'order_continued',
  BEGIN_CHECKOUT = 'begin_checkout',
  REWARD_ADD = 'reward_add',
  REWARD_REMOVE = 'reward_remove',
  PROMO_CODE_ADD = 'promo_code_add',
  PROMO_CODE_FAIL = 'promo_code_fail',
  PROMO_CODE_MIN_AMOUNT = 'promo_code_min_amount',
  PROMO_CODE_SUCCESS = 'promo_code_success',
  PROMO_CODE_REMOVE = 'promo_code_remove',
  PROMOTION_APPLIED = 'promotion_applied',
  PROMOTION_INVALID = 'promotion_invalid',
  SIGN_IN = 'sign_in',
  LOG_OUT = 'log_out',
  CREATE_ACCOUNT = 'create_account',
  PURCHASE = 'purchase',
  PURCHASE_ERROR = 'purchase_error',
  MAX_ORDER_AMOUNT = 'max_order_amount',
  SWITCH_ORDER_TYPE = 'switch_order_type',
  SWITCH_PAYMENT_METHOD = 'switch_payment_method',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_PAYMENT_INFO_FAIL = 'add_payment_info_fail',
  ORDERING_UNAVAILABLE = 'ordering_unavailable',
  FORGOT_PASSWORD = 'forgot_password',
  RESET_PASSWORD = 'reset_password',
  POSITIVE_FEEDBACK = 'positive_feedback',
  NEUTRAL_FEEDBACK = 'neutral_feedback',
  NEGATIVE_FEEDBACK = 'negative_feedback',
  QUICK_REORDER = 'quick_reorder',
  QUICK_REORDER_SHOWN = 'quick_reorder_shown',
  GO_TO_ORDER_HISTORY = 'go_to_order_history',
  LOCATION_SELECTION = 'locationSelection',
  LOCATION_SELECTION_CLOSE = 'locationSelectionClose',
  LOCATION_SELECTION_CONFIRMATION = 'locationSelectionConfirmation',
  LOCATION_SELECTION_LOCATION = 'locationSelectionLocation',
  JOIN_TABLE = 'joinTable',
  LEAVE_TABLE = 'leaveTable',
  SEND_ITEMS = 'sendItems',
  ORDER_LOCKED = 'orderLocked',
  ORDER_UNLOCKED = 'orderUnlocked',
  LOCKED_CHECKOUT = 'lockedCheckout',
  LOCKED_ITEMS = 'lockedItems',
  SUGGEST_ITEMS = 'suggest_items',
  ORDER_TOTAL_CHANGE = 'orderTotalChange',
  SPLIT_AMOUNT_START = 'splitAmountStart',
  GUEST_ADDED = 'guestAdded',
  GUEST_REMOVED = 'guestRemoved',
  GUEST_JOINED_DURING_CHECKOUT = 'guestJoinedDuringCheckout',
  SHARE_SHEET_VIEW = 'shareSheetView',
  GIFT_CARD_CTA = 'giftCardCTA',
  GIFT_CARD_PROMO_LIVE = 'giftCardPromoLive',
  JOIN_ORDER_MODAL = 'join_order_modal',
  JOINED_ORDER = 'joined_order',
  REJOIN_LINK = 'rejoin_link',
  WRITTEN_FEEDBACK = 'writtenFeedback',
  SIT_FIRE_VISIT = 'sitAndFireVisit',
  SIT_FIRE_SEND = 'send-when-seated-click',
  START_A_TAB_VIEW = 'start-tab-screen-view',
  START_A_TAB_SUCCESS = 'start-tab-success',
  START_A_TAB_FAIL = 'start-tab-fail',
  UNAVAILABLE_ITEMS = 'unavailable-items',
  RECOMMENDATIONS_LOADED = 'suggestions-loaded',
}
