import {
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  DrawerContentProps,
  ModalContentProps,
  DrawerFooter,
  ModalFooter,
  BoxProps,
  IconButtonProps,
  ModalHeaderProps,
  ModalFooterProps,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { useScreenSizeState } from '@olo-web/client-state';
import { ConditionalWrapper } from '@olo-web/components/atoms/Wrappers/ConditionalWrapper';
import { CommonModalHeader, CommonDrawerHeader } from '@olo-web/components/organisms/Modals/common';
import React from 'react';

type TDrawerOrModalContentWrapper = {
  title?: string | JSX.Element;
  canClose?: boolean;
  children?: any[] | any;
  drawerProps?: DrawerContentProps;
  modalProps?: ModalContentProps;
  bodyProps?: BoxProps;
  closeButtonProps?: Omit<IconButtonProps, 'aria-label'>;
  floatHeader?: boolean;
  footer?: any;
  drawerHeaderProps?: Omit<ModalHeaderProps, 'as'> & {
    dataTestId?: string;
    headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  };
  footerProps?: ModalFooterProps;
  isLoading?: boolean;
};

export const DrawerOrModalContentWrapper = ({
  title,
  canClose = true,
  children,
  drawerProps = {},
  modalProps = {},
  bodyProps = {},
  closeButtonProps = {},
  floatHeader = false,
  footer,
  drawerHeaderProps = {},
  footerProps = {},
  isLoading = false,
}: TDrawerOrModalContentWrapper) => {
  const { isXsDown } = useScreenSizeState();
  const loadingOverlay = (
    <Flex
      position="absolute"
      background="whiteAlpha.400"
      justifyContent="center"
      alignItems="center"
      left={0}
      top={0}
      bottom={0}
      right={0}
      zIndex={100}
    >
      <Spinner />
    </Flex>
  );
  return (
    <ConditionalWrapper
      condition={isXsDown}
      wrapper={(kids) => (
        <DrawerContent
          borderRadius="8px 8px 0 0"
          maxH="calc(100% - env(safe-area-inset-top))"
          {...drawerProps}
          boxShadow="0 2px 10px 2px rgba(0,0,0,0.1)"
        >
          {isLoading && loadingOverlay}
          {title && (
            <CommonDrawerHeader
              position={floatHeader ? 'absolute' : 'relative'}
              {...drawerHeaderProps}
            >
              {title}
            </CommonDrawerHeader>
          )}
          {canClose && <DrawerCloseButton mt={2} {...closeButtonProps} data-testid="close-modal" />}
          <DrawerBody px={4} {...bodyProps}>
            {kids}
          </DrawerBody>
          {footer && <DrawerFooter {...footerProps}>{footer}</DrawerFooter>}
        </DrawerContent>
      )}
      falseWrapper={(kiddos) => (
        <ModalContent {...modalProps}>
          {isLoading && loadingOverlay}
          {title && (
            <CommonModalHeader
              position={floatHeader ? 'absolute' : 'relative'}
              {...drawerHeaderProps}
            >
              {typeof title === 'string' ? title : title}
            </CommonModalHeader>
          )}
          {canClose && <ModalCloseButton {...closeButtonProps} data-testid="close-modal" />}
          <ModalBody maxH={'calc(100% - env(safe-area-inset-top))'} {...bodyProps}>
            {kiddos}
          </ModalBody>
          {footer && <ModalFooter {...footerProps}>{footer}</ModalFooter>}
        </ModalContent>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};
