import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useIsLoggedIn, useSendEvent } from '@olo-web/utils/common/hooks';
import axios, { AxiosError } from 'axios';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useMutation, UseMutationOptions } from 'react-query';
import { IOrderDiscount } from '@olo-web/domain/orders/types';
import { useCustomerDispatch } from '@olo-web/client-state';

type AddPromoCodeToOrderProps = {
  merchantId: string;
  orderId: string;
  promoCode: string;
  dealType?: string;
  dealSource?: string;
};

export const addPromoCodeToOrder = async ({
  merchantId,
  orderId,
  promoCode,
}: AddPromoCodeToOrderProps): Promise<IOrder> => {
  const url = `/api/merchants/${merchantId}/orders/${orderId}/discounts`;
  return await axios.post(url, { promoCode });
};

export const useAddPromoCodeToOrder = (
  options?: UseMutationOptions<any, AxiosError, AddPromoCodeToOrderProps>
) => {
  const { sendEvent } = useSendEvent();
  const customerDispatch = useCustomerDispatch();
  const updateOrderInCache = useUpdateOrderInCache();
  const isLoggedIn = useIsLoggedIn();

  return useMutation(addPromoCodeToOrder, {
    ...options,
    onMutate: (variables) => {
      sendEvent(EAnalyticsEventNames.PROMO_CODE_ADD, {
        googleAnalytics: {
          promoCode: variables.promoCode,
        },
      });
    },
    onError: (error: any, variables) => {
      // BE returns 400 when user tries to apply marketing deal but does not have minimum order amount
      // required in his bag - we add a temporary inactive deal into customer.promos state and
      // act upon item total change and redo this request if minimum order is met
      if (error.response.data?.code === 400 && error.response.data?.additionalData) {
        const data = error.response.data?.additionalData;
        const promo: IOrderDiscount = {
          promoCode: variables.promoCode,
          promotionType: 1,
          promotionName: '',
          promotionId: '',
          discountValue: '',
          adjustmentKind: data.adjustmentKind,
          adjustmentValue: data.adjustmentValue,
          shouldBeAutoApplied: !isLoggedIn,
          isActive: false,
          minOrderValue: data.minOrderValue,
          maxDiscountValue: data.maxDiscountValue,
        };
        customerDispatch({
          type: 'ADD_PROMO',
          payload: promo,
        });
        sendEvent(EAnalyticsEventNames.PROMO_CODE_MIN_AMOUNT, {
          googleAnalytics: {
            promoCode: variables.promoCode,
          },
          prometheus: {
            source: variables.promoCode,
            payload: 'min order value not met',
          },
        });
      }
    },
    onSuccess: (response, variables, context) => {
      const { data } = response;
      updateOrderInCache(data);
      const promo: IOrderDiscount = {
        promoCode: variables.promoCode,
        promotionType: 1, // Only the client considers promotionType of 1 === promo code. On the backend it means no type.
        promotionName: data.discounts[0].discountName,
        promotionId: data.discounts[0].id,
        marketingPromotionId: data.discounts[0].spotOnRewardRedemptionId,
        discountValue: '',
        isActive: true,
        shouldBeAutoApplied: false,
        minOrderValue: data.discounts[0].minOrderValue,
        maxDiscountValue: data.discounts[0].maxDiscountValue,
        adjustmentKind: data.discounts[0].adjustmentKind,
        adjustmentValue: data.discounts[0].adjustmentValue,
      };
      customerDispatch({
        type: 'ADD_PROMO',
        payload: promo,
      });
      sendEvent(EAnalyticsEventNames.PROMO_CODE_SUCCESS, {
        googleAnalytics: {
          promoCode: variables.promoCode,
        },
      });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
