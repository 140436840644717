import React from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import {
  CheckoutContextProvider,
  CustomerContextProvider,
  MenuContextProvider,
  ModalContextProvider,
  AlertContextProvider,
  SavedOrderProvider,
  DeliveryAddressProvider,
  GlobalUIContextProvider,
  ScheduleTypeProvider,
  OrderTipsProvider,
  OrderUnavailabilityProvider,
  ContactInfoProvider,
  LevelupContextProvider,
  SavedDineInContextProvider,
  OrderReviewContextProvider,
} from '@olo-web/client-state';
import { NatsProvider } from '@olo-web/client-state/natsContext';
import { useCombinedTheme } from '@olo-web/theme/hooks/useCombinedTheme';
import { ScreenSizeProvider } from '@olo-web/client-state';

export const ProviderProvider = ({
  children,
  globalUIState,
}: {
  children: React.ReactNode;
  globalUIState?: any;
}) => {
  const theme = useCombinedTheme();

  return (
    <ChakraProvider theme={theme}>
      <ScreenSizeProvider>
        <ModalContextProvider>
          <AlertContextProvider>
            <CustomerContextProvider>
              <SavedOrderProvider>
                <OrderTipsProvider>
                  <OrderUnavailabilityProvider>
                    <DeliveryAddressProvider>
                      <CheckoutContextProvider>
                        <MenuContextProvider>
                          <ContactInfoProvider>
                            <GlobalUIContextProvider initialStore={globalUIState}>
                              <ScheduleTypeProvider>
                                <LevelupContextProvider>
                                  <NatsProvider>
                                    <SavedDineInContextProvider>
                                      <OrderReviewContextProvider>
                                        <CSSReset />
                                        {children}
                                      </OrderReviewContextProvider>
                                    </SavedDineInContextProvider>
                                  </NatsProvider>
                                </LevelupContextProvider>
                              </ScheduleTypeProvider>
                            </GlobalUIContextProvider>
                          </ContactInfoProvider>
                        </MenuContextProvider>
                      </CheckoutContextProvider>
                    </DeliveryAddressProvider>
                  </OrderUnavailabilityProvider>
                </OrderTipsProvider>
              </SavedOrderProvider>
            </CustomerContextProvider>
          </AlertContextProvider>
        </ModalContextProvider>
      </ScreenSizeProvider>
    </ChakraProvider>
  );
};
