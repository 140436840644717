import { Icon, IconButton, ModalHeaderProps, Flex, Box, Heading } from '@chakra-ui/react';
import { useModalDispatch, useModalState } from '@olo-web/client-state';
import React, { FC } from 'react';
import { ArrowLeft } from 'react-feather';

interface I extends Omit<ModalHeaderProps, 'as'> {
  children: React.ReactNode;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const CommonModalHeader: FC<I> = (props) => {
  const { children, headingLevel = 2, ...rest } = props;
  const { previousModal = [] } = useModalState();
  const modalDispatch = useModalDispatch();

  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_PREVIOUS_MODAL',
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      data-testid="modal-header"
      padding="10px 24px 10px 24px"
      fontWeight="600"
      fontSize="lg"
      {...rest}
    >
      {previousModal.length > 0 && (
        <IconButton
          variant="ghost"
          mr={2}
          ml={-2}
          aria-label="Go back to previous modal"
          icon={<Icon as={ArrowLeft} h={6} w={6} />}
          onClick={handleClick}
        />
      )}
      <Flex align="center" h="44px">
        {typeof children === 'string' ? (
          <Heading as={`h${headingLevel}`} size="md">
            {children}
          </Heading>
        ) : (
          children
        )}
      </Flex>
    </Box>
  );
};
